// Are you wondering why do we have consts.ts and consts_internal.ts???
// When deploying a stage, the lab/consts.ts replaces this file, so we want to keep this
// file as minimal as possible.  consts_internal has some code that we don't want lab messing with,
// so it needs to live there.

import {
  HOSTNAME_EXTERNAL,
  COGNITO_ENDPOINT_EXTERNAL,
  MANIFESTO_URL_EXTERNAL,
  IMAGES_BUCKET_EXTERNAL,
  COGNITO_CLIENT_ID_EXTERNAL,
} from "./endpoints";
export const PLACEHOLDER_HUDDLE_IMG =
  "https://cdn.vegmanifesto.com/HUDDLES/VEG%20Hoboken.jpg";
export const ARTICLE_BY_ID_URL = `${HOSTNAME_EXTERNAL}/article`;
export const ANALYTICS = `${HOSTNAME_EXTERNAL}/analytics`;
export const ANALYTICS_SHARE = `${ANALYTICS}/share`;
export const ALL_ARTICLES = `${HOSTNAME_EXTERNAL}/articles`;
export const LOCATION = `${HOSTNAME_EXTERNAL}/location`;
export const DAILY_ARTICLE_URL = `${HOSTNAME_EXTERNAL}/article/daily`;
export const HUDDLE_IMAGE_URL = `${HOSTNAME_EXTERNAL}/images/gethuddle`;
export const USER_HUDDLE_IMAGE_URL = `${HOSTNAME_EXTERNAL}/images/user/huddle`;
export const USER_HUDDLES = `${HOSTNAME_EXTERNAL}/user/huddles`;
export const READING_LISTS = `${HOSTNAME_EXTERNAL}/readinglists`;
export const READING_LIST = `${HOSTNAME_EXTERNAL}/readinglist`;
export const USER_STREAK_STATS_ENDPOINT = `${HOSTNAME_EXTERNAL}/user/streak/stats`;
export const VEGWIDESTATS_ENDPOINT = `${HOSTNAME_EXTERNAL}/home/vegwidestats`;
export const WEEKLYSTATS_LISTS = `${HOSTNAME_EXTERNAL}/weeklystats`;
export const USER_READ_ARTICLES = `${HOSTNAME_EXTERNAL}/user/read-articles`;
export const USER_FAVORITE_ARTICLES = `${HOSTNAME_EXTERNAL}/user/favorites`;
export const USER_SHARE = `${HOSTNAME_EXTERNAL}/user/share`;
export const USER_RESET_ARTICLES = `${HOSTNAME_EXTERNAL}/user/reset-articles`;
export const NEWS = `${HOSTNAME_EXTERNAL}/news`;
export const IMAGES_GETURL = `${HOSTNAME_EXTERNAL}/images/geturl`;
export const USERS = `${HOSTNAME_EXTERNAL}/users`;
export const HUDDLES = `${HOSTNAME_EXTERNAL}/huddle`;
export const HUDDLES_IMAGES_PUT = `${HOSTNAME_EXTERNAL}/images/user/huddle`;
export const HUDDLES_IMAGES_DELETE = `${HOSTNAME_EXTERNAL}/images/deleted`;
export const BANTER_WORD = `${HOSTNAME_EXTERNAL}/banter`;
export const BANTER_USER = `${HOSTNAME_EXTERNAL}/user/banter`;
export const BANTER_STATS = `${HOSTNAME_EXTERNAL}/user/banter/stats`;
export const BANTER_RESET = `${HOSTNAME_EXTERNAL}/user/banter/reset`;
export const CONNECTIONS_GAME = `${HOSTNAME_EXTERNAL}/vegism`;
export const CONNECTIONS_USER = `${HOSTNAME_EXTERNAL}/user/vegism`;
export const CONNECTIONS_STATS = `${HOSTNAME_EXTERNAL}/user/vegism/stats`;
export const CONNECTIONS_RESET = `${HOSTNAME_EXTERNAL}/user/vegism/reset`;
export const TRIVIA_GAME = `${HOSTNAME_EXTERNAL}/trivia`;
export const TRIVIA_USER = `${HOSTNAME_EXTERNAL}/user/trivia`;
export const TRIVIA_SAVE = `${HOSTNAME_EXTERNAL}/user/trivia/save`;
export const TRIVIA_STATS = `${HOSTNAME_EXTERNAL}/user/trivia/stats`;
export const TRIVIA_RESET = `${HOSTNAME_EXTERNAL}/user/trivia/reset`;
export const TRIVIA_RESET_VEGX = `${HOSTNAME_EXTERNAL}/user/trivia/reset?config=vegx`;
export const VEGX = `${HOSTNAME_EXTERNAL}/vegx`;

export const COGNITO_ENDPOINT = COGNITO_ENDPOINT_EXTERNAL;
export const MANIFESTO_URL = MANIFESTO_URL_EXTERNAL;
export const IMAGES_BUCKET = IMAGES_BUCKET_EXTERNAL;
export const COGNITO_CLIENT_ID = COGNITO_CLIENT_ID_EXTERNAL;
export const MIXED_VEGGIES_WORKPLACE =
  "https://vegolution.workplace.com/groups/1035408176861297";
export const FEEDBACK_FORM =
  "https://forms.monday.com/forms/embed/852beeb1ae7e1f44f09b87986df16571";

export const WEEKDAYS = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const QUICK_CLICKS = [
  {
    title: "The VEG Manifesto",
    content: "It’s all here, just click and read!",
    to: "reading-list/manifestobychapter",
    readingListId: "manifestobychapter",
  },
  {
    title: "News and updates",
    content: "Learn about new content and tech updates",
    to: "news",
  },
  {
    title: "My list",
    content: "Star your favorite sections and find them here",
    to: "my-list",
  },
];

export const HUDDLE_PHOTOS = [
  "TMI.jpg",
  "VEG Dallas.jpg",
  "VEG Katy.jpg",
  "VEG Shrewsbury.jpg",
  "VEG White Plains.jpg",
  "VEG Alpharetta.jpg",
  "VEG Denver.jpg",
  "VEG Lincoln Park.jpg",
  "VEG South Brooklyn.jpg",
  "VEG Williamsburg.jpg",
  "VEG Aventura.jpg",
  "VEG Edgewater.jpg",
  "VEG Nanuet.jpg",
  "VEG South Loop.jpg",
  "VEG_Chelsea_257.jpg",
  "VEG Boston.jpg",
  "VEG Encinitas.jpg",
  "VEG Newton.jpg",
  "VEG South Tampa.png",
  "VEG_Chelsea_260.jpg",
  "VEG Chelsea.jpg",
  "VEG Fort Worth.jpg",
  "VEG Paramus.jpg",
  "VEG Tampa.jpg",
  "VEG_Chelsea_267.jpg",
  "VEG Clifton.jpg",
  "VEG Georgetown.jpg",
  "VEG Philly.jpg",
  "VEG UES.jpg",
  "VEG Commack.jpg",
  "VEG Hoboken.jpg",
  "VEG San Ramon.jpg",
  "VEG Union.jpg",
];

export const months = [
  { label: "January", value: "january" },
  { label: "February", value: "february" },
  { label: "March", value: "march" },
  { label: "April", value: "april" },
  { label: "May", value: "may" },
  { label: "June", value: "june" },
  { label: "July", value: "july" },
  { label: "August", value: "august" },
  { label: "September", value: "september" },
  { label: "October", value: "october" },
  { label: "November", value: "november" },
  { label: "December", value: "december" },
];

export const ALPHABET = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

export const dayAbbreviations = {
  Monday: "MON",
  Tuesday: "TUE",
  Wednesday: "WED",
  Thursday: "THU",
  Friday: "FRI",
  Saturday: "SAT",
  Sunday: "SUN",
};
